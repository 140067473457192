import { ROUTER_PATH_BASE } from '@/config'
import { Hypocenter } from '@/features/hypocenter'
import { Torii } from '@/features/torii'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { NotFound } from './NotFound'

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`${ROUTER_PATH_BASE}hypocenter`} element={<Hypocenter />} />
        <Route path={`${ROUTER_PATH_BASE}torii`} element={<Torii />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
