import { useEffect, useMemo, useState } from 'react'

import { PanoramaInfo } from './useInfo'

export const usePanoramaImage = (info: PanoramaInfo | null) => {
  const canvas = useMemo(() => document.createElement('canvas') as unknown as HTMLCanvasElement, [])
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  useEffect(
    function drawOffscreenCanvas() {
      const ctx = canvas?.getContext('2d')
      if (!info || !canvas || !ctx) {
        return
      }

      canvas.width = info.canvasWidth
      canvas.height = info.canvasHeight

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      info.images.forEach((i) => {
        if (i.img) {
          const img = i.img
          const x = i.x
          const y = i.y
          ctx.drawImage(img, 0, 0, img.width, img.height, x, y, img.width, img.height)
        }
      })

      canvas.toBlob((result) => {
        if (result) {
          setImageUrl(URL.createObjectURL(result))
        }
      })
    },
    [canvas, info]
  )

  return imageUrl
}
