/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Dispatch, forwardRef, MutableRefObject, SetStateAction, useCallback, useRef, useState } from 'react'

import { Fade } from '@mui/material'
import Hls from 'hls.js'
import { VideoInfo } from '../hooks/useInfo'

const videoStyle = css`
  position: fixed;
  min-width: 100%;
  min-height: 100vh;
  z-index: 999;
  @media (aspect-ratio: 1/1), (min-aspect-ratio: 1/1) {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-aspect-ratio: 1/1) {
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`

const applyVideoSrc = (e: HTMLVideoElement, info: VideoInfo) => {
  if (info.hlsUrl) {
    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(info.hlsUrl)
      hls.attachMedia(e)
      return
    }
    if (e.canPlayType('application/vnd.apple.mpegurl')) {
      e.src = info.hlsUrl
      return
    }
  }
  e.src = info.url
}

export type OverlayVideoRef = {
  canPlayThroughVideosRef: MutableRefObject<boolean>
  videoElement: HTMLVideoElement
  setFadeIn: Dispatch<SetStateAction<boolean>>
}

export type OverlayVideoProps = {
  info: VideoInfo
}

export const OverlayVideo = forwardRef<OverlayVideoRef, OverlayVideoProps>(({ info }, ref) => {
  const canPlayThroughVideosRef = useRef(false)
  const refCalledRef = useRef(false)
  const [fadeIn, setFadeIn] = useState(false)

  const videoRef = useCallback((e: HTMLVideoElement) => {
    if (refCalledRef.current || !e) {
      return
    }
    refCalledRef.current = true

    if (ref) {
      const refObject: OverlayVideoRef = {
        canPlayThroughVideosRef,
        setFadeIn,
        videoElement: e,
      }
      if (typeof ref === 'function') {
        ref(refObject)
      } else {
        ref.current = refObject
      }
    }

    e.addEventListener('canplaythrough', () => {
      canPlayThroughVideosRef.current = true
    })
    applyVideoSrc(e, info)
    e.load()
  }, [])

  return (
    <Fade in={fadeIn} easing={info.appearance?.easing} timeout={info.appearance?.timeout} appear={false}>
      <video ref={videoRef} css={videoStyle} playsInline></video>
    </Fade>
  )
})
