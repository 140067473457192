import { useCallback, useEffect, useRef, useState } from 'react'

const STARTUP_DEGREES_CHECK_COUNT = 3

export type PermissionState = 'granted' | 'denied' | 'unsupported'

export type UnavailableReason = 'inprogress' | 'unsupported' | 'permissiondenied' | 'needtorequestpermission'

export type CompassState = {
  readonly absolute: boolean
  readonly alpha: number
  readonly beta: number
  readonly gamma: number
  readonly degrees: number
  readonly unavailable: UnavailableReason | null
}

export type WebkitDeviceOrientationEvent = DeviceOrientationEvent & {
  readonly webkitCompassHeading: number | undefined
  readonly webkitCompassAccuracy: number | undefined
  readonly requestPermission: (() => Promise<PermissionState>) | undefined
}

const makeUnavailableState = (reason: UnavailableReason): CompassState => ({
  absolute: false,
  alpha: NaN,
  beta: NaN,
  gamma: NaN,
  degrees: NaN,
  unavailable: reason,
})

// https://www.w3.org/TR/orientation-event/
const degtorad = Math.PI / 180 // Degree-to-Radian conversion
function compassHeading({ alpha, beta, gamma }: DeviceOrientationEvent) {
  var _x = beta ? beta * degtorad : 0 // beta value
  var _y = gamma ? gamma * degtorad : 0 // gamma value
  var _z = alpha ? alpha * degtorad : 0 // alpha value

  var cY = Math.cos(_y)
  var cZ = Math.cos(_z)
  var sX = Math.sin(_x)
  var sY = Math.sin(_y)
  var sZ = Math.sin(_z)

  // Calculate Vx and Vy components
  var Vx = -cZ * sY - sZ * sX * cY
  var Vy = -sZ * sY + cZ * sX * cY

  // Calculate compass heading
  var compassHeading = Math.atan(Vx / Vy)

  // Convert compass heading to use whole unit circle
  if (Vy < 0) {
    compassHeading += Math.PI
  } else if (Vx < 0) {
    compassHeading += 2 * Math.PI
  }

  return compassHeading * (180 / Math.PI) // Compass Heading (in degrees)
}

export const useCompass: () => [CompassState, () => Promise<PermissionState>] = () => {
  const [state, setState] = useState<CompassState>(makeUnavailableState('inprogress'))
  const alreadyWorking = useRef(false)

  const startDeviceOrientationEvent = useCallback(() => {
    if (alreadyWorking.current) {
      return
    }
    alreadyWorking.current = true

    let removedUnnecessaryEvent = false
    let startupDegrees: number[] = []
    const orientation = (_event: Event) => {
      const event = <WebkitDeviceOrientationEvent>_event

      const available = event.alpha !== null && event.beta !== null && event.gamma !== null
      if (!removedUnnecessaryEvent) {
        if (event.absolute && available) {
          window.removeEventListener('deviceorientation', orientation, true)
          removedUnnecessaryEvent = true
        } else if (!event.absolute && event.webkitCompassHeading !== undefined) {
          window.removeEventListener('deviceorientationabsolute', orientation, true)
          removedUnnecessaryEvent = true
        }
      }
      if (!available) {
        return
      }

      let degrees: number = compassHeading(event)
      if (!event.absolute) {
        degrees = event.webkitCompassHeading || 0

        if (startupDegrees.length < STARTUP_DEGREES_CHECK_COUNT) {
          startupDegrees = startupDegrees.filter((x) => x === degrees)
          startupDegrees.push(degrees)
        }

        let corrected = startupDegrees[0] + compassHeading(event)
        corrected %= 360
        if (corrected < 0) {
          corrected += 360
        }

        degrees = corrected
      }

      setState({
        absolute: event.absolute,
        alpha: event.alpha || 0,
        beta: event.beta || 0,
        gamma: event.gamma || 0,
        degrees,
        unavailable: null,
      })
    }

    window.addEventListener('deviceorientation', orientation, true)
    window.addEventListener('deviceorientationabsolute', orientation, true)
  }, [])

  const requestPermission = useCallback(async () => {
    if (alreadyWorking.current) {
      return 'granted'
    }

    let res: PermissionState
    const event = window.DeviceOrientationEvent as unknown as WebkitDeviceOrientationEvent
    if (event) {
      if (typeof event.requestPermission === 'function') {
        res = await event.requestPermission()
      } else {
        res = 'granted'
      }
    } else {
      res = 'unsupported'
    }

    switch (res) {
      case 'granted':
        startDeviceOrientationEvent()
        break
      case 'denied':
        setState(makeUnavailableState('permissiondenied'))
        break
      case 'unsupported':
        setState(makeUnavailableState('unsupported'))
        break
      default:
        new Error('Not Implemented')
    }

    return res
  }, [startDeviceOrientationEvent])

  useEffect(() => {
    const event = window.DeviceOrientationEvent as unknown as WebkitDeviceOrientationEvent
    if (!event) {
      setState(makeUnavailableState('unsupported'))
      return
    }
    requestPermission().catch(() => {
      setState(makeUnavailableState('needtorequestpermission'))
    })
  }, [requestPermission])

  return [state, requestPermission]
}
