/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import useAsyncEffect from 'use-async-effect'

import { ErrorModal } from '@/components'

const videoStyleBase = css`
  position: fixed;
  z-index: -99;
`

const errorMessage = ['カメラの許可がありません。', 'このページを再読み込みした後、', 'カメラの許可を行ってください。']

export type BackgroundWebcamState = 'waiting' | 'error' | 'working'

export type BackgroundWebcamProps = {
  children?: ((state: BackgroundWebcamState) => ReactNode) | undefined
  onError?: (() => void) | undefined
}

export const BackgroundWebcam = ({ children, onError }: BackgroundWebcamProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [state, setState] = useState<BackgroundWebcamState>('waiting')
  const [videoStyle, setVideoStyle] = useState(css`
    ${videoStyleBase}
    top: 0px;
    left: 0px;
  `)

  const resize = useCallback(() => {
    const container = containerRef.current!
    const video = videoRef.current!

    let w, h
    const videoRatio = video.videoWidth / video.videoHeight
    const containerRatio = container.clientWidth / container.clientHeight
    if (videoRatio > containerRatio) {
      h = container.clientHeight
      w = h * videoRatio
    } else {
      w = container.clientWidth
      h = w / videoRatio
    }

    setVideoStyle(css`
      ${videoStyleBase}
      top: ${-(h - container.clientHeight) / 2}px;
      left: ${-(w - container.clientWidth) / 2}px;
      width: ${w}px;
      height: ${h}px;
    `)
  }, [])

  useAsyncEffect(
    async () => {
      const video = videoRef.current!
      const canvas = canvasRef.current!

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
              facingMode: 'environment',
            },
          })

          await new Promise<void>((resolve, reject) => {
            let alreadyLoadedmetadata = false
            video.addEventListener('loadedmetadata', () => {
              try {
                video.play()
                video.setAttribute('width', video.videoWidth.toString())
                video.setAttribute('height', video.videoHeight.toString())
                canvas.setAttribute('width', video.videoWidth.toString())
                canvas.setAttribute('height', video.videoHeight.toString())

                const ctx = canvas.getContext('2d')!
                const draw = () => {
                  ctx.drawImage(video, 0, 0, canvas.width, video.height)
                  requestAnimationFrame(draw)
                }
                draw()

                resize()
                if (!alreadyLoadedmetadata) {
                  alreadyLoadedmetadata = true
                  window.addEventListener('resize', resize)
                  resolve()
                }
              } catch (e) {
                reject(e)
              }
            })
            video.srcObject = stream
          })

          setState('working')
          return true
        } catch {}
      }

      setState('error')
      onError && onError()
      return false
    },
    (result) => result && window.addEventListener('resize', resize),
    []
  )

  const openErrorModal = useMemo(() => state === 'error', [state])
  return (
    <div
      ref={containerRef}
      css={css`
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
      `}
    >
      <video
        ref={videoRef}
        css={css`
          display: none;
        `}
        muted
        playsInline
      />
      <canvas ref={canvasRef} css={videoStyle}></canvas>
      {children && children(state)}
      <ErrorModal open={openErrorModal} message={errorMessage} showReloadButton={true} />
    </div>
  )
}
