import { useEffect } from 'react'

import { ON_ERROR_REDIRECT_TO } from '@/config'

export const NotFound = () => {
  useEffect(() => {
    window.location.href = ON_ERROR_REDIRECT_TO
  }, [])

  return <h1 style={{ margin: 8 }}>Not Found</h1>
}
