/** @jsxImportSource @emotion/react */
import { BackgroundWebcam } from '@/components'
import { css } from '@emotion/react'
import { Button, CircularProgress, Fade } from '@mui/material'
import { useCallback, useMemo, useRef, useState } from 'react'

import { CompassWithFallback as Compass } from '@/components'
import { Tutorial } from '@/components/Tutorial'
import { useCompass } from '@/hooks'
import { isInDegreesRange } from '@/utils'

import { OverlayImage, OverlayVideo, OverlayVideoRef } from '../components'
import { useInfo, videoIdPrefix } from '../hooks'

const videoHiddenIdleTime = 500

enum ShowVideoState {
  hiding,
  hidden,
  showing,
  loading,
  show,
}

export const Torii = () => {
  const compass = useCompass()
  const [compassState] = compass
  const info = useInfo()

  const [showVideoState, setShowVideoState] = useState<ShowVideoState>(ShowVideoState.hidden)
  const [showSecondPlayButton, setShowSecondPlayButton] = useState(false)

  const overlayVideoRefs = useRef<Record<string, OverlayVideoRef>>({})

  const overlayVideoNodes = useMemo(() => {
    return info?.videos?.map((info, i) => (
      <OverlayVideo key={i} info={info} ref={(r) => r && (overlayVideoRefs.current[info.id] = r)} />
    ))
  }, [info])

  const isInPlayable = useMemo(() => {
    if (!info || info.degreesRange.length < 2) {
      return false
    }
    return isInDegreesRange(compassState.degrees, info.degreesRange, info.degreesRangeOffset)
  }, [info, compassState])

  const playVideoHandle = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!info) {
        return
      }

      const videoId = videoIdPrefix + e.currentTarget.id
      const videoInfo = info.videos.find((x) => x.id === videoId)
      if (!videoInfo) {
        return
      }

      const videoRef = overlayVideoRefs.current[videoInfo.id]
      const video = videoRef.videoElement

      try {
        await new Promise<void>((resolve, reject) => {
          let retryCount = 100
          const playForEnabledSound = () =>
            setTimeout(async () => {
              try {
                if (retryCount <= 0) {
                  reject()
                  return
                }
                await video.play()
                video.pause()
                video.currentTime = 0
                resolve()
              } catch (_) {
                retryCount--
                playForEnabledSound()
              }
            }, 10)
          playForEnabledSound()
        })
      } catch (_) {
        video.muted = true
      }

      const ended = () => {
        video.removeEventListener('ended', ended)
        setShowVideoState(ShowVideoState.hiding)
        videoRef.setFadeIn(false)
        setTimeout(() => {
          setShowVideoState(ShowVideoState.hidden)
          setShowSecondPlayButton(true)
          video.currentTime = 0
        }, videoInfo.appearance?.timeout?.exit ?? videoHiddenIdleTime)
      }
      video.addEventListener('ended', ended)

      const play = () =>
        setTimeout(async () => {
          try {
            await video.play()
          } catch (_) {
            play()
          }
        }, 100)

      let endToWaitForHidingOverlayImage = false
      let onEndToWaitForHidingOverlayImage: (() => void) | null = null
      const waitForHidingOverlayImage = () => {
        setTimeout(() => {
          endToWaitForHidingOverlayImage = true
          onEndToWaitForHidingOverlayImage && onEndToWaitForHidingOverlayImage()
        }, info.overlayAppearance?.timeout?.enter ?? videoHiddenIdleTime)
      }

      const start = () => {
        setShowVideoState(ShowVideoState.showing)
        waitForHidingOverlayImage()
        onEndToWaitForHidingOverlayImage = () => {
          videoRef.setFadeIn(true)
          setShowVideoState(ShowVideoState.show)
          play()
        }
      }

      if (!videoRef.canPlayThroughVideosRef.current) {
        setShowVideoState(ShowVideoState.loading)
        const playEventListener = () => {
          video.removeEventListener('canplaythrough', playEventListener)
          start()
        }
        video.addEventListener('canplaythrough', playEventListener)
      } else {
        start()
      }
    },
    [info]
  )

  return (
    <BackgroundWebcam>
      {(cameraState) =>
        cameraState !== 'working' ? null : (
          <>
            <Fade in={showVideoState === ShowVideoState.loading}>
              <div
                css={css`
                  position: fixed;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 4001;
                  height: 100%;
                  width: 100%;
                `}
              >
                {showVideoState === ShowVideoState.loading ? <CircularProgress color="secondary" size={80} /> : null}
              </div>
            </Fade>
            <div>{overlayVideoNodes}</div>

            <Compass compass={compass}>
              <Tutorial images={info?.tutorialImages}>
                <div
                  css={css`
                    position: relative;
                    width: 100%;
                    height: 100vh;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      position: fixed;
                      top: 85%;
                      left: 0;
                      z-index: 410;
                      width: 100%;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    <Fade
                      in={showVideoState === ShowVideoState.hidden || showVideoState === ShowVideoState.loading}
                      easing={info?.overlayAppearance?.easing}
                      timeout={info?.overlayAppearance?.timeout}
                      appear={false}
                    >
                      <div>
                        {!info ? null : (
                          <OverlayImage
                            available={isInPlayable}
                            appearance={info?.availableAppearance}
                            imageUrl={info.imageUrl}
                            unavailableImageUrl={info.unavailableImageUrl}
                            heights={info.overlaySize.heights}
                          />
                        )}
                        <Fade
                          in={isInPlayable}
                          easing={info?.availableAppearance?.easing}
                          timeout={info?.availableAppearance?.timeout}
                          appear={false}
                        >
                          <div
                            css={css`
                              display: flex;
                              flex-direction: row;
                            `}
                          >
                            <Button
                              css={
                                showSecondPlayButton
                                  ? css`
                                      margin-right: 36px;
                                      margin-left: auto;
                                      @media (orientation: portrait) {
                                        margin-right: 36px;
                                      }
                                      @media (orientation: landscape) {
                                        margin-right: 64px;
                                      }
                                    `
                                  : css`
                                      margin-right: auto;
                                      margin-left: auto;
                                    `
                              }
                              id="first"
                              variant="contained"
                              onClick={playVideoHandle}
                            >
                              被爆
                            </Button>
                            <Button
                              css={css`
                                display: ${showSecondPlayButton ? 'block' : 'none'};
                                margin-right: auto;
                                margin-left: 36px;
                                @media (orientation: portrait) {
                                  margin-left: 36px;
                                }
                                @media (orientation: landscape) {
                                  margin-left: 64px;
                                }
                              `}
                              id="second"
                              variant="contained"
                              onClick={playVideoHandle}
                            >
                              復元
                            </Button>
                          </div>
                        </Fade>
                      </div>
                    </Fade>
                  </div>
                </div>
              </Tutorial>
            </Compass>
          </>
        )
      }
    </BackgroundWebcam>
  )
}
