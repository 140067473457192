/** @jsxImportSource @emotion/react */
import { ReactNode, useMemo } from 'react'

import { CompassState, PermissionState, UnavailableReason } from '@/hooks/useCompass'
import { css } from '@emotion/react'
import { Button, CircularProgress, Typography } from '@mui/material'

import { ErrorModal } from '@/components'

const errorMessages: Record<UnavailableReason, string[]> = {
  needtorequestpermission: ['方位センサーの許可を行います。'],
  unsupported: ['このデバイスは方位センサーをサポートしていません。'],
  permissiondenied: [
    '方位センサーの許可がありません。',
    'デバイスの設定から方位センサーの許可を行い、',
    'このページを再読みして下さい。',
  ],
  inprogress: [],
}

const absoluteCenterStyle = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

type Props = {
  compass: [CompassState, () => Promise<PermissionState>]
  children?: ReactNode | ((state: CompassState) => ReactNode) | undefined
}

export const CompassWithFallback = ({ compass, children }: Props) => {
  const [state, requestPermission] = compass

  const errorMessage = useMemo(() => {
    if (!state.unavailable) {
      return undefined
    }
    return errorMessages[state.unavailable]
  }, [state])

  if (!state.unavailable) {
    return <>{typeof children === 'function' ? children(state) : children}</>
  } else if (state.unavailable === 'inprogress') {
    return (
      <div css={absoluteCenterStyle}>
        <CircularProgress
          css={css`
            margin: 16px;
          `}
          size={80}
        />
        <Typography>デバイスの方位センサーの応答を待っています</Typography>
      </div>
    )
  } else {
    return (
      <ErrorModal open={true} showReloadButton={state.unavailable === 'permissiondenied'} message={errorMessage}>
        <div>
          {state.unavailable === 'needtorequestpermission' ? (
            <Button
              css={css`
                margin-top: 32px;
              `}
              onClick={requestPermission}
              variant="contained"
            >
              OK
            </Button>
          ) : null}
        </div>
      </ErrorModal>
    )
  }
}
