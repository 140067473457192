import { complementAssetUrl } from '@/utils/complementAssetUrl'
import { useState } from 'react'
import useAsyncEffect from 'use-async-effect'

import { axiosForData as axios } from '@/lib'
import { addOffsetDegrees } from '@/utils'

export type ImageInfo = {
  x: number
  y: number
  url: string
  img?: HTMLImageElement
}

export type LinkInfo = {
  degreesRange: [number, number]
  text: string
  url: string
  degreesRangeOffset: number
}

export type PanoramaInfo = {
  canvasWidth: number
  canvasHeight: number
  fovs: number[]
  cameraOffsetY: [number, number]
  skyRotateY: number
  images: ImageInfo[]
  links: LinkInfo[]
}

const loadImage = (src: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (e) => reject(e)
    img.src = src
  })

export const useInfo = () => {
  const [info, setInfo] = useState<PanoramaInfo | null>(null)

  useAsyncEffect(async () => {
    const res = await axios.get<PanoramaInfo>('/hypocenter.json')
    const info = res.data
    if (
      info.canvasWidth === undefined ||
      info.canvasWidth < 0 ||
      info.canvasHeight === undefined ||
      info.canvasHeight < 0 ||
      info.cameraOffsetY === undefined ||
      typeof info.cameraOffsetY[0] !== 'number' ||
      typeof info.cameraOffsetY[1] !== 'number' ||
      info.skyRotateY === undefined ||
      info.fovs?.length !== 2 ||
      info.fovs[0] < 0 ||
      info.fovs[1] < 0 ||
      info.links.findIndex(
        (x) =>
          x.degreesRange === undefined ||
          typeof x.degreesRange[0] !== 'number' ||
          x.degreesRange[0] < 0 ||
          x.degreesRange[0] > 360 ||
          typeof x.degreesRange[1] !== 'number' ||
          x.degreesRange[1] < 0 ||
          x.degreesRange[1] > 360 ||
          typeof x.text !== 'string' ||
          typeof x.url !== 'string'
      ) >= 0
    ) {
      console.error('invalid data')
      return
    }
    await Promise.all(
      info.images.map(async (i) => {
        i.url = complementAssetUrl(i.url)
        i.img = await loadImage(i.url)
      })
    )

    info.links.forEach((x) => {
      if (x.degreesRange[0] > x.degreesRange[1]) {
        const offset = 360 - x.degreesRange[0]
        x.degreesRange[0] = addOffsetDegrees(x.degreesRange[0], offset)
        x.degreesRange[1] = addOffsetDegrees(x.degreesRange[1], offset)
        x.degreesRangeOffset = offset
      } else {
        x.degreesRangeOffset = 0
      }
    })

    setInfo(info)
  }, [])

  return info
}
