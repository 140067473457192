import { useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'

import { axiosForData as axios } from '@/lib'
import { TutorialImageInfo } from '@/types'
import { addOffsetDegrees } from '@/utils'
import { complementAssetUrl } from '@/utils/complementAssetUrl'

import { Appearance } from '@/types/Appearance'

export const videoIdPrefix = 'video-'

export type VideoInfo = {
  id: string
  url: string
  hlsUrl?: string | undefined
  appearance?: Appearance | undefined
}

export type Info = {
  degreesRange: [number, number]
  degreesRangeOffset: number
  imageUrl: string
  unavailableImageUrl: string
  videos: VideoInfo[]
  overlayAppearance?: Appearance | undefined
  overlaySize: { heights: [string, string] }
  availableAppearance?: Appearance | undefined
  tutorialAppearance?: Appearance | undefined
  tutorialImages: TutorialImageInfo[]
}

export const useInfo = () => {
  const [info, setInfo] = useState<Info>()

  useAsyncEffect(async () => {
    const res = await axios<Info>('/torii.json')
    const info = res.data
    info.videos.forEach((x) => {
      x.id = videoIdPrefix + x.id
      x.url = complementAssetUrl(x.url)
      x.hlsUrl = complementAssetUrl(x.hlsUrl)
    })
    info.tutorialImages.forEach((x) => {
      x.url = complementAssetUrl(x.url)
    })
    info.imageUrl = complementAssetUrl(info.imageUrl)
    info.unavailableImageUrl = complementAssetUrl(info.unavailableImageUrl)

    if (info.degreesRange[0] > info.degreesRange[1]) {
      const offset = 360 - info.degreesRange[0]
      info.degreesRange[0] = addOffsetDegrees(info.degreesRange[0], offset)
      info.degreesRange[1] = addOffsetDegrees(info.degreesRange[1], offset)
      info.degreesRangeOffset = offset
    } else {
      info.degreesRangeOffset = 0
    }
    setInfo(info)
  }, [])

  return info
}
