/** @jsxImportSource @emotion/react */

import { TutorialImageInfo } from '@/types'
import { css } from '@emotion/react'
import { CircularProgress, Fade } from '@mui/material'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Appearance } from '@/types/Appearance'

const appearance: Appearance = {
  easing: {
    enter: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
    exit: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  },
  timeout: {
    appear: 500,
    enter: 500,
    exit: 500,
  },
}

export type TutorialProps = {
  images: TutorialImageInfo[] | undefined | null
  children?: ReactNode | undefined
}

const tutorialImageStyle = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
  object-fit: fill;
`

export const Tutorial = ({ images: _images, children }: TutorialProps) => {
  const [tutorialCount, setTutorialCount] = useState(-1)
  const [loadedImageCount, setlLadedImageCount] = useState(0)

  const [images, setImages] = useState<TutorialImageInfo[] | null>(null)
  useEffect(() => {
    if (!_images) {
      return
    }
    if (images) {
      return
    }
    setImages(_images)
  }, [images, _images])

  const incrementLoadedImageCount = useCallback(() => setlLadedImageCount((x) => x + 1), [])

  const isAllImageLoaded = useMemo(() => {
    if (!images) {
      return false
    }
    return images.length === loadedImageCount
  }, [images, loadedImageCount])

  const tutorialImages = useMemo(() => {
    if (!images) {
      return null
    }
    return images.map((x, i) => (
      <Fade key={i} in={i === tutorialCount} easing={appearance?.easing} timeout={appearance?.timeout}>
        <img onLoad={incrementLoadedImageCount} css={tutorialImageStyle} src={x.url} />
      </Fade>
    ))
  }, [images, tutorialCount])

  const isStartedTimerRef = useRef(false)
  useEffect(() => {
    if (!isAllImageLoaded) {
      return
    }
    if (isStartedTimerRef.current) {
      return
    }
    isStartedTimerRef.current = true

    let count = 0
    const progressTutorial = () => {
      if (!images) {
        return
      }
      setTutorialCount(count)
      if (count < images.length) {
        setTimeout(progressTutorial, images[count].displayTime)
        count++
      }
    }
    progressTutorial()
  }, [isAllImageLoaded])

  const showTutorial = useMemo(() => !images || tutorialCount < images.length, [images, tutorialCount])

  return (
    <>
      <Fade in={showTutorial} easing={appearance?.easing} timeout={appearance?.timeout} appear={false}>
        <div>
          <div
            css={css`
              position: fixed;
              width: 100%;
              height: 100vh;
              z-index: 6001;
              background-color: #444444;
            `}
          >
            {tutorialImages}
            {!isAllImageLoaded ? (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  width: 100%;
                `}
              >
                <CircularProgress size={80} />
              </div>
            ) : null}
          </div>
          )
        </div>
      </Fade>
      <Fade in={!showTutorial} easing={appearance?.easing} timeout={appearance?.timeout} appear={false}>
        <div>{children}</div>
      </Fade>
    </>
  )
}
