/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fade } from '@mui/material'
import { useMemo } from 'react'

import { Appearance } from '@/types/Appearance'

export type OverlayImageProps = {
  available: boolean
  appearance?: Appearance
  imageUrl: string
  unavailableImageUrl: string
  heights: [string, string]
}

export const OverlayImage = ({ available, appearance, imageUrl, unavailableImageUrl, heights }: OverlayImageProps) => {
  const overlayImageStyle = useMemo(
    () => css`
      position: fixed;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 50vh;
      object-fit: fill;
      @media (orientation: portrait) {
        height: ${heights[0]};
      }
      @media (orientation: landscape) {
        height: ${heights[1]};
      }
    `,
    [...heights]
  )

  return (
    <>
      <Fade in={available} easing={appearance?.easing} timeout={appearance?.timeout} appear={false}>
        <img
          css={css`
            z-index: 402;
            ${overlayImageStyle}
          `}
          src={imageUrl}
        />
      </Fade>
      <Fade in={!available} easing={appearance?.easing} timeout={appearance?.timeout} appear={false}>
        <img
          css={css`
            z-index: 401;
            ${overlayImageStyle}
          `}
          src={unavailableImageUrl}
        />
      </Fade>
    </>
  )
}
