/** @jsxImportSource @emotion/react */
import { ReactNode, useCallback, useMemo } from 'react'

import { css } from '@emotion/react'
import CachedIcon from '@mui/icons-material/Cached'
import { Button, Modal, Typography } from '@mui/material'

export type ErrorModalProps = {
  open: boolean
  message?: string | string[] | undefined
  showReloadButton?: boolean | undefined
  children?: ReactNode | undefined
}

export const ErrorModal = ({ open, message: _message, showReloadButton, children }: ErrorModalProps) => {
  const message = useMemo(() => {
    const messages = _message ? (typeof _message === 'string' ? [_message] : _message) : []
    return messages.map((m, i) => <Typography key={i}>{m}</Typography>)
  }, [_message])

  const reloadButtonClickHandle = useCallback(() => window.location.reload(), [])

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div
        css={css`
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          display: flex;
          flex-flow: column;
          min-width: 60%;
          max-width: 80%;
          background-color: #ffffff;
          border: 2px solid #000;
          box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
            0px 1px 14px 0px rgba(0, 0, 0, 0.12);
          padding: 16px 32px 24px;
        `}
      >
        <div
          css={css`
            text-align: left;
          `}
        >
          {message}
        </div>
        {!showReloadButton ? null : (
          <div
            css={css`
              margin-top: 16px;
            `}
          >
            <Button variant="outlined" startIcon={<CachedIcon />} onClick={reloadButtonClickHandle}>
              再読み込み
            </Button>
          </div>
        )}
        {children}
      </div>
    </Modal>
  )
}
