/** @jsxImportSource @emotion/react */
import 'aframe'

import { css } from '@emotion/react'
import { Button, CircularProgress, Fade, Slider } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

import { BackgroundWebcam, CompassWithFallback as Compass } from '@/components'
import { useCompass } from '@/hooks'
import { isInDegreesRange } from '@/utils'

import { AFrameScene } from '../components/AFrameScene'
import { useInfo } from '../hooks/useInfo'
import { usePanoramaImage } from '../hooks/usePanoramaImage'

export const Hypocenter = () => {
  const compass = useCompass()
  const [compassState] = compass
  const info = useInfo()
  const imageUrl = usePanoramaImage(info)

  const [alphaValue, setAlphaValue] = useState(100)
  const onChangeAlphaValueSlider = useCallback((event: Event, value: number | number[], activeThumb: number) => {
    if (typeof value === 'number') {
      setAlphaValue(value)
    }
  }, [])

  const available = useMemo(() => !!(info && imageUrl), [info, imageUrl])

  const currentLinkInfo = useMemo(() => {
    if (!info) {
      return undefined
    }
    return info.links.find((x) => isInDegreesRange(compassState.degrees, x.degreesRange, x.degreesRangeOffset))
  }, [info, compassState])

  const linkButtonClickHandle = useCallback(() => {
    if (currentLinkInfo) {
      window.open(currentLinkInfo.url, '_blank')?.focus()
    }
  }, [currentLinkInfo])

  return (
    <BackgroundWebcam>
      {(webcamState) =>
        webcamState !== 'working' ? null : (
          <Compass compass={compass}>
            {(c) => (
              <>
                {available ? (
                  <>
                    <div
                      css={css`
                        position: fixed;
                        z-index: 402;
                        width: 100%;
                        top: 28%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        @media (orientation: portrait) {
                          top: 28%;
                        }
                        @media (orientation: landscape) {
                          top: 8%;
                        }
                      `}
                    >
                      <Fade in={!!currentLinkInfo}>
                        <Button onClick={linkButtonClickHandle} variant="contained">
                          {currentLinkInfo?.text}
                        </Button>
                      </Fade>
                    </div>
                    <div
                      css={css`
                        position: fixed;
                        z-index: 401;
                        width: 100%;
                        top: 90%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                      `}
                    >
                      <Slider
                        css={css`
                          width: 60%;
                        `}
                        onChange={onChangeAlphaValueSlider}
                        value={alphaValue}
                      />
                    </div>
                  </>
                ) : null}
                <div
                  css={css`
                    position: fixed;
                    z-index: 400;
                    width: 100%;
                    height: 65%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @media (orientation: portrait) {
                      height: 65%;
                    }
                    @media (orientation: landscape) {
                      height: 100%;
                    }
                    text-align: center;
                  `}
                >
                  <AFrameScene info={info} alpha={alphaValue} degrees={c.degrees} imageUrl={imageUrl}>
                    <div
                      css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 100%;
                      `}
                    >
                      <CircularProgress size={80} />
                    </div>
                  </AFrameScene>
                </div>
              </>
            )}
          </Compass>
        )
      }
    </BackgroundWebcam>
  )
}
