import AFRAME from 'aframe'
import * as THREE from 'three'

import { ReactNode, useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { PanoramaInfo } from '../hooks/useInfo'

type ShaderData = {
  src: TexImageSource | OffscreenCanvas
  transparent: boolean
}

export type aFrameSceneProps = {
  degrees: number
  alpha: number
  imageUrl: string | null
  info: PanoramaInfo | null
  children?: ReactNode | undefined
}

export const AFrameScene = ({ degrees, alpha, imageUrl, info, children }: aFrameSceneProps) => {
  const registerdShaderRef = useRef(false)
  useEffect(() => {
    if (registerdShaderRef.current) {
      return
    }
    registerdShaderRef.current = true
    AFRAME.registerShader('transparent-image', {
      schema: {
        src: { type: 'map' },
      },
      init: function (data: ShaderData) {
        const texture = new THREE.Texture()
        texture.image = data.src
        texture.format = THREE.RGBAFormat
        texture.needsUpdate = true
        data.transparent = true

        this.material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
        })
      },
    })
  })

  const windowSize = useWindowSize()

  const firstCameraFovRef = useRef(0)
  const firstCameraOffsetYRef = useRef(0)
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    if (isReady) {
      return
    }
    if (info && windowSize.height > 0 && windowSize.width > 0) {
      const index = windowSize.height >= windowSize.width ? 0 : 1
      firstCameraFovRef.current = info.fovs[index]
      firstCameraOffsetYRef.current = info.cameraOffsetY[index]
      setIsReady(true)
    }
  }, [isReady, info, windowSize])

  useEffect(() => {
    if (info) {
      const camera = document.querySelector('#camera')
      camera?.setAttribute('camera', 'fov', windowSize.height >= windowSize.width ? info.fovs[0] : info.fovs[1])
    }
  }, [windowSize, info?.fovs])

  useEffect(() => {
    const cameraRoot = document.querySelector('#camera-root')
    cameraRoot?.setAttribute('rotation', `0 ${-degrees} 0`)
  }, [degrees])

  useEffect(() => {
    const sky = document.querySelector('#sky')
    sky?.setAttribute('opacity', alpha / 100)
  }, [info, alpha])

  return (
    <>
      {isReady && info && imageUrl ? (
        <>
          <a-scene id="scene" embedded="" vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">
            <a-assets>
              <img id="canvas" src={imageUrl} />
            </a-assets>
            <a-sky
              id="sky"
              src="#canvas"
              radius="1"
              rotation={`0 ${info.skyRotateY} 0`}
              material="shader: transparent-image; src: #canvas"
            ></a-sky>
            <a-entity id="camera-root" position={`0 ${firstCameraOffsetYRef.current} 0`}>
              <a-camera
                id="camera"
                fov={`${firstCameraFovRef.current}`}
                rotation-reader
                look-controls="enabled: false;"
              ></a-camera>
            </a-entity>
          </a-scene>
        </>
      ) : (
        children
      )}
    </>
  )
}
